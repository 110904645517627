import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: 170,
    height: 170,
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize:40
  },
}));

const CirculoNumero = ({number}) => {
    const classes = useStyles();
    return (

        <div className={classes.root}>
      
        <Avatar alt="Remy Sharp" className={classes.large} >
            {number}
        </Avatar>
      </div>

      );
}
 
export default CirculoNumero;