import React, { useState, useEffect } from "react";
import VentanaEstadisticas from "./VentanaEstadisticas";
import Swal from "sweetalert2";
import banner1 from "./imagenes/be_my_dog/banner_1.jpeg";
import banner2 from "./imagenes/be_my_dog/banner_2.jpg";
import banner3 from "./imagenes/be_my_dog/banner_3.jpg";
import icono from "./imagenes/be_my_dog/icono_principal.png";
import SimpleImageSlider from "react-simple-image-slider";
import FullScreenIcon from "@material-ui/icons/Fullscreen";
import {
  greenIcon,
  blueIcon,
  redIcon,
  blackIcon,
  greyIcon,
  violetIcon,
  yellowIcon,
  orangeIcon,
  goldIcon,
} from "./components/marcadores/Marcadores";
import paises from "./Paises/countries";
import { popupContent, popupHead, popupText, okText } from "./popupStyles";
import { makeStyles } from "@material-ui/core/styles";
import estados from "./Paises/states";
import {
  Map,
  Marker,
  Popup,
  TileLayer,
  LayersControl,
  Circle,
  FeatureGroup,
  Pane,
} from "react-leaflet";
import Backdrop from "@material-ui/core/Backdrop";
import Lightbox from "react-image-lightbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./app.css";
import { firebaseApp } from "./components/FireBase";
import ngeohash from "ngeohash";
import "firebase/firestore";

const db = firebaseApp.firestore(firebaseApp);

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function Mapas(props) {
  const classes = useStyles();

  const [cargandoUbicaciones, setCargandoUbicaciones] = useState(false);

  const [posicion, setPosicion] = useState([]);
  const [paisesMaster, setPaises] = useState(null);
  const [estadosMaster, setEstados] = useState(null);
  const [iniciarMapa, setIniciarMapa] = useState(false);
  const [datosFireBase, setDatosFireBase] = useState([]);
  const [imagen, setImagenes] = useState([]);
  const [numeroMundo, setNumeroMundo] = useState(null);
  const [numeroPais, setNumeroPais] = useState(null);
  const [numeroEstado, setNumeroEstado] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [zoom, setZoom] = useState(11);

  const [lightbox, setLightbox] = useState({
    open: false,
    fotos: [],
    photoIndex: 0,
  });

  const [abrirUbicacionesCercanas, setAbrirUbicacionesCercanas] =
    useState(false);

  const estadosLista = estados.states;

  const numero = 0;

  useEffect(() => {
    let estalogueado = false;

    // firebase.auth().onAuthStateChanged((usuario) => {
    //   if (!usuario) {
    //     props.history.push("/");
    //   } else
    const estadosLista = estados.states;
    const paisesLista = paises.countries;

    let validador;
    let validadorPais;

    let latitude = 0;
    let longitude = 0;

    (async () => {
      await Swal.mixin({
        confirmButtonText: "Next &rarr;",

        progressSteps: ["1", "2", "3", "4"],
      }).queue([
        {
          title: "!Bienvenido a Be My Dog Maps!",
          text: "Esta es la versión web con la cual podrás ver un mapa con todos los perros en adopción cercanos a ti, esto aplica para cualquier parte del mundo :)",
          imageUrl:
            "https://res.cloudinary.com/be-my-dog/image/upload/f_auto,q_auto/v1665959876/others/banner_1.4826becd_1_rdussl.jpg",
          imageAlt: "Custom image",

          // imageWidth: 900,
          // imageHeight: 400,
          confirmButtonText: "Siguiente",
          showCloseButton: true,
        },
        {
          title: "Publica Gratis",
          text: "Ya seas un centro de adopcion o un ciudadano comun, puedes publicar en adopcion gratuitamente todos los perros que quieras, esta publicacion aparecera en la app y en esta version web para ayudarte aun mas con la difusion",
          imageUrl:
            "https://res.cloudinary.com/be-my-dog/image/upload/f_auto,q_auto/v1665959874/others/banner_2.6aced7de_elncub.jpg",
          imageAlt: "Custom image",
          confirmButtonText: "Siguiente",
          showCloseButton: true,
          // imageWidth: 900,
          // imageHeight: 400,
        },

        {
          title: "Descarga la app",
          text: "Tener la app te permite publicar y buscar perros en adopcion de una manera mas comoda.",
          imageUrl:
            "https://res.cloudinary.com/be-my-dog/image/upload/f_auto,q_auto/v1665959873/others/banner_3.ba07b461_kdlobs.jpg",
          imageAlt: "Custom image",
          confirmButtonText: "Siguiente",
          imageHeight: 400,
          footer: `
          <div style='display:flex,
          justifyContent: 'space between'
   
        
        
        '>
        <div>
          <a href="https://apps.apple.com/us/app/be-my-dog/id1603397958" target="_blank">Descargar en IOS</a>
           
          <a 
          
          style='margin-left:30px;'
          href="https://play.google.com/store/apps/details?id=com.tinderperros&hl=es_MX" target="_blank">Descargar en Android</a>
            </div>
            `,
          showCloseButton: true,
        },
        {
          title: "100% sin fines de lucro",
          text: "Be My Dog es una proyecto sin fines de lucro, programado y mantenido por una sola persona, la mejor manera de ayudarnos es seguirnos en redes ahi publicamos tambien todos los perritos, eso nos permite ser escuchados por mas personas y difundir aun mas las llamadas de auxilio que hay en el mundo",
          imageUrl:
            "https://res.cloudinary.com/be-my-dog/image/upload/f_auto,q_auto/v1665959873/others/icono_principal.5a1b8ce2_1_e9k8ff.png",
          imageAlt: "Custom image",
          confirmButtonText: "Siguiente",
          imageHeight: 300,

          showCloseButton: true,
        },
      ]);

      // navigator.geolocation.getCurrentPosition(
      //   function (position) {
      //     setPosicion([position.coords.latitude, position.coords.longitude]);
      //     latitude = position.coords.latitude;
      //     longitude = position.coords.longitude;

      //     localStorage.setItem("localizacion", "verdadero");
      //   },
      //   (error) => {
      //     console.log(error.message);
      //   }
      // );

      navigator.geolocation.getCurrentPosition(
        function (position) {
          setPosicion([position.coords.latitude, position.coords.longitude]);
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;

          // localStorage.setItem("localizacion", "verdadero");
        },
        async (error) => {
          const result = await Swal.fire({
            title: "Permite tu ubicacion para poder continuar",
            text: "Be My Dog es una aplicacion que no guarda ni rastrea tu ubicacion, simplemente la necesita para que la app web pueda funcionar, si la denegaste ve a ajustes del sitio y permite la ubicacion",
            icon: "warning",

            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok, ya permiti mi ubicacion",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });

          if (result.value) {
            setRefresh(!refresh);
          }

          /**Repetimos una vez mas */
          // navigator.geolocation.getCurrentPosition(
          //   (position) => {
          //     setPosicion([
          //       position.coords.latitude,
          //       position.coords.longitude,
          //     ]);
          //     latitude = position.coords.latitude;
          //     longitude = position.coords.longitude;

          //     localStorage.setItem("localizacion", "verdadero");
          //     setRefresh(!refresh);
          //   },
        }
      );

      // if (localStorage.getItem("localizacion") !== "verdadero") {

      // }

      const { value: numeroEstado } = await Swal.fire({
        title: "Elige tu pais",
        input: "select",
        allowOutsideClick: false,
        allowEscapeKey: false,
        inputOptions: paisesLista.map((au) => au.name),
        inputPlaceholder: "Selecciona un pais",

        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value !== "") {
              setPaises(paisesLista[value].name);
              validadorPais = paisesLista[value].name;

              validador = estadosLista
                .map((ciclo1) => ({
                  estado: ciclo1.name,
                  id: ciclo1.id_country,
                }))
                .filter((ciclo2) => paisesLista[value].id === ciclo2.id);
              resolve();
            } else {
              resolve("Selecciona un pais primero");
            }
          });
        },
      });

      const { value: numeroEstado2 } = await Swal.fire({
        title: "Elige tu estado",
        input: "select",
        inputOptions: validador.map((ciclo) => ciclo.estado),
        inputPlaceholder: "Selecciona un estado",
        allowOutsideClick: false,
        allowEscapeKey: false,

        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value !== "") {
              setCargandoUbicaciones(true);
              setEstados(validador[value].estado);
              validador = validador[value].estado;

              resolve();
            } else {
              resolve("Selecciona un estado primero");
            }
          });
        },
      });

      if (numeroEstado) {
        //sacamos el geohash de la ubicacion actual
        let geohashActual = ngeohash
          .encode(latitude, longitude)
          .substring(0, 3);

        const resultRestaurants = [];
        const datos = db
          .collection("datosMascotas")
          .where("aprobado", "==", true)
          .where("estado", "==", validador)
          .where("pais", "==", validadorPais)
          .orderBy("geohash", "asc")
          .startAt(geohashActual)
          .limit(100);

        const snap2 = await db
          .collection("datosMascotas")
          .where("status", "==", "verificado")
          .where("pais", "==", validadorPais)
          .get();

        const snap3 = await db
          .collection("datosMascotas")
          .where("status", "==", "verificado")
          .get();

        const response = await datos.get();

        response.forEach((doc) => {
          let restaurant = doc.data();
          restaurant.id = doc.id;

          resultRestaurants.push({ ...restaurant });
        });

        if (resultRestaurants.length !== 0) {
          Swal.fire(
            "Listo....!!!",
            "Las ubicaciones que aparecen a continuación, son todos los perros en adopción que hay en el estado que seleccionaste.",
            "success"
          ).then(() => {
            setAbrirUbicacionesCercanas(true);
          });
        } else {
          Swal.fire(
            "No hay perros disponibles",
            "Al parecer no hay ningun perro en adopcion en el lugar que solicitas, comparte para que cualquier persona o centro de adopcion pueda subir perros en adopcion por tu zona"
          );
        }

        setNumeroEstado(response.size);
        setNumeroPais(snap2.size); //TE REGRESA EL TOTL DE ARCHIVOS
        setNumeroMundo(snap3.size); //TE REGRESA EL TOTL DE ARCHIVOS
        setCargandoUbicaciones(false);
        setIniciarMapa(true);
        setDatosFireBase(resultRestaurants);
      }
    })();
  }, [refresh]);

  return iniciarMapa ? (
    <Map center={posicion} zoom={zoom}>
      <VentanaEstadisticas
        paisesMaster={paisesMaster}
        estadosMaster={estadosMaster}
        numeroMundo={numeroMundo}
        numeroEstado={numeroEstado}
        numeroPais={numeroPais}
        datosFireBase={datosFireBase}
        posicion={posicion}
        setPosicion={setPosicion}
        setZoom={setZoom}
        abrirUbicacionesCercanas={abrirUbicacionesCercanas}
        setAbrirUbicacionesCercanas={setAbrirUbicacionesCercanas}
      />

      {lightbox.open && (
        <Lightbox
          mainSrc={lightbox.fotos[lightbox.photoIndex]}
          nextSrc={
            lightbox.fotos[(lightbox.photoIndex + 1) % lightbox.fotos.length]
          }
          prevSrc={
            lightbox.fotos[
              (lightbox.photoIndex + lightbox.fotos.length - 1) %
                lightbox.fotos.length
            ]
          }
          onCloseRequest={() => {
            setLightbox({
              ...lightbox,
              open: false,
            });
          }}
          onMovePrevRequest={
            () => {
              setLightbox({
                ...lightbox,
                photoIndex:
                  (lightbox.photoIndex + lightbox.fotos.length - 1) %
                  lightbox.fotos.length,
              });
            }
            // this.setState({
            //   photoIndex: (photoIndex + images.length - 1) % images.length,
            // })
          }
          onMoveNextRequest={
            () => {
              setLightbox({
                ...lightbox,
                photoIndex:
                  (lightbox.photoIndex + lightbox.fotos.length + 1) %
                  lightbox.fotos.length,
              });
            }
            // this.setState({
            //   photoIndex: (photoIndex + 1) % images.length,
            // })
          }
        />
      )}

      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {datosFireBase.map((dato, index) => {
        return (
          <Marker
            key={index}
            icon={
              dato.categoria === "agua"
                ? blueIcon
                : dato.categoria === "higiene"
                ? blueIcon
                : dato.categoria === "alimentos"
                ? greenIcon
                : dato.categoria === "hospitales"
                ? redIcon
                : dato.categoria === "punto publico de ayuda"
                ? orangeIcon
                : dato.categoria === "otros"
                ? blackIcon
                : dato.categoria === "ropa"
                ? violetIcon
                : dato.categoria === "insumos medicos"
                ? goldIcon
                : orangeIcon
            }
            position={[
              dato.direccionCoordenadas.latitude,
              dato.direccionCoordenadas.longitude,
            ]}
          >
            <Popup className="request-popup" style={{ zIndex: 60000 }}>
              <div style={popupContent}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "50%",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <SimpleImageSlider
                      navSize={30}
                      width={220}
                      height={220}
                      images={dato.fotos}
                      showBullets={true}
                      showNavs={true}
                      bgColor="none"
                    />
                    <div
                      onClick={() => {
                        setLightbox({
                          photoIndex: 0,
                          fotos: dato.fotos,
                          open: true,
                        });
                      }}
                      style={{
                        position: "absolute",
                        bottom: 2,
                        right: 10,
                        cursor: "pointer",
                      }}
                    >
                      <FullScreenIcon style={{ color: "#fff", fontSize: 40 }} />
                    </div>
                  </div>
                  {/* <img
                    style={{
                      borderRadius: "50%",
                    }}
                    src={dato.fotos[0]}
                    width="220"
                    height="220"
                  /> */}
                </div>
                <div style={popupHead}>{dato.raza}</div>
                <span style={popupText}>{dato.descripcion}</span>

                <div style={{ display: "flex", height: 30 }}>
                  <h3 style={{ marginRight: 15, width: 69 }}>Nombre: </h3>
                  <h4>{dato.nombrePersona}</h4>
                </div>

                <div style={{ display: "flex", height: 30 }}>
                  <h3 style={{ marginRight: 15, width: 69 }}>Numero: </h3>
                  <h4>{dato.telefono}</h4>
                </div>
                <div style={{ display: "flex" }}>
                  <h3 style={{ marginRight: 15, width: 69 }}>Correo:</h3>
                  <h4>{dato.correo}</h4>
                </div>
              </div>
            </Popup>
          </Marker>
        );
      })}
    </Map>
  ) : (
    <>
      <Map zoom={13}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
      </Map>
      <Backdrop className={classes.backdrop} open={cargandoUbicaciones}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Mapas;
