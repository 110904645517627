import React from "react";
import Mapas from "./Mapas";
import NuevaCuenta from "./InicioCuenta";
import InicioLogin from "./InicioLogin";
import Contraseña from "./Contraseña";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        {/* <Route exact path='/' component={InicioLogin}/> */}
        {/* <Route exact path='/nueva-cuenta' component={NuevaCuenta}/> */}
        <Route exact path="/" component={Mapas} />
        {/* <Route exact path='/contraseña' component={Contraseña}/>  */}
      </Switch>
    </Router>
  );
}

export default App;
