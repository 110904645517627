import React from 'react';
import {Doughnut} from 'react-chartjs-2';



const DoughnutMaster = ({
    paisesMaster,
    estadosMaster,
    numeroMundo,
    numeroEstado,
    numeroPais})=>{

        const data = {
            labels: [
                estadosMaster,
                paisesMaster,
                'Todo el mundo'
            ],
            datasets: [{
                data: [numeroEstado, numeroPais, numeroMundo],
                backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56'
                ],
                hoverBackgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56'
                ]
            }]
        };
        
  

  
    return (
        <>
      
        <Doughnut data={data}  
        width={200}
  height={150} 
  options={{ maintainAspectRatio: false }}
  />
   </>
     
    );
  
};

export default DoughnutMaster;