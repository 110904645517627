import React,{useState, useEffect} from 'react';
import copera from './imagenes/94702562_117230306627219_6939220235983519744_o.jpg';
import coperaLogo from './imagenes/copera-logo.jpg';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import 'firebase/auth';



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  image: {
    backgroundImage: "url("+copera+")",
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function InicioLogin(props) {

   const [logueado, setLogueado] = useState(false);
   const [comprobadorAlerta, setComprobadorAlerta] = useState('');

   const [usuario,setUsuario] = useState({
       email: '',
       password: ''
   });


  const classes = useStyles();

  useEffect(()=>{
    
      firebase.auth().onAuthStateChanged(user=>{

        if(user){
            
            props.history.push('/mapa-copera');
            
            
        }else{
            
        }
          
      })

  

  },[])


  const iniciarApp = async (e)=>{
    e.preventDefault();
    setLogueado(true);
    await firebase.auth().signInWithEmailAndPassword(usuario.email, usuario.password)
    .then(()=>{
        props.history.push('/mapa-copera');
    }
        
    ).catch((e)=>{
        
       

        if(e.code === "auth/user-not-found"){
          setComprobadorAlerta('No existe ningun usuario con ese correo');
        }else if(e.code === "auth/invalid-email"){
          setComprobadorAlerta('El correo esta mal escrito');
        }else if(e.code === "auth/wrong-password"){
          setComprobadorAlerta('La contraseña es incorrecta');

        }
    })
    setLogueado(false);
    //props.history.push('/mapa-copera');
  

  }

  const onChange = (e)=>{
      
      setUsuario({
          ...usuario,
          [e.target.name]: e.target.value
      });

  }

  return (

  
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={require('./imagenes/logo-copera.png')} style={{width:240, height:80}}></img>
          <Typography component="h1" variant="h5" style={{paddingBottom:10 }}>
            Inicar Sesion
          </Typography>
          {comprobadorAlerta !== '' && <Alert severity="error">{comprobadorAlerta}</Alert>}
          <form className={classes.form} onSubmit={iniciarApp} >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo Electronico"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={onChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={onChange}
            />
          
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{marginTop:20}}
            >
              Iniciar Sesion
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/contraseña" variant="body2">
                  ¿olvidaste tu contraseña?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/nueva-cuenta" variant="body2">
                  {"¿No tienes cuenta? Crea Una :)"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Backdrop className={classes.backdrop} open={logueado}>
          <CircularProgress color="inherit"/>
        </Backdrop >
      </Grid>
    </Grid>

  );
}