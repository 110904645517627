import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function CrearPreguntas() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            ¿Cómo puedo subir un perro a Be My Dog?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Para subir un perro a Be My Dog necesitas hacerlo directamente desde
            la app disponible para android y ios de manera totalmente gratuita,
            puedes subir todos los que necesites en cualquier momento
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            Consejos de seguridad
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <p>
              ° De preferencia trata de no ir a la ubicación exacta, usa los
              datos de contacto que vienen en la publicación para comunicarte
              con la persona encargada del perrito.
            </p>
            <p>
              ° Te recordamos que la comunicación con otras personas es tu
              responsabilidad, por lo que te recomendamos seguir las
              recomendaciones básicas para evitar cualquier tipo de
              inconveniente, y realizar de manera exitosa lo que más nos
              interesa, promover la adopción de perros.
            </p>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            ¿Como se que los datos son seguros y reales?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Be My Dog está comprometido con tu seguridad, por eso te recordamos
            que cada publicación que aparece en la app y en este mapa, es
            verificada por nuestro equipo una vez nos llegan los datos, en pocas
            palabras ninguna publicación o datos puede aparecer hasta que
            nosotros lo autoricemos, proceso en el cual nos corroboramos todos
            los datos esto es para evitar algún tipo de abuso por parte de los
            ciudadanos o información fraudulenta.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            ¿Como puedo contactar al equipo de Be My Dog?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Contactar con Be My Dog es muy fácil, puedes contactarte con
            nosotros a través de nuestras redes o a través del correo
            bemydog@gmail.com o enviando un correo al creador del proyecto
            contact@luisromodev.com
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            ¿Todas estas publicaciones aparecen también en redes?{" "}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Cada publicación que llega a Be My Dog es compartido en nuestras
            redes también, esto con motivo de aumentar la difusión y
            probabilidades de que un perro pueda ser adoptado.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
