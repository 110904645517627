import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Doughnut from "./estadisticas/Doughnut";
import PolarCopera from "./estadisticas/PolarCopera";
import HorizontalBarCopera from "./estadisticas/HorizontalBarCopera";
//ICONOS
import FacebookIcon from "@material-ui/icons/Facebook";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import InstagramIcon from "@material-ui/icons/Instagram";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { LazyLoadImage } from "react-lazy-load-image-component";
import iconoLargo from "./imagenes/be_my_dog/Be-My-Dog-side-Full.png";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import BarCopera from "./estadisticas/BarCopera";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CirculoNumero from "./estadisticas/CirculoNumero";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Hidden from "@material-ui/core/Hidden";
import DialogAyuda from "./DialogAyuda";
import DialogPreguntas from "./DialogPreguntas";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      height: theme.spacing(60),
    },
  },
  appBar: {
    position: "relative",
    zIndex: 4000,
    backgroundColor: "black",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  list: {
    width: 450,
  },
  fullList: {
    width: "auto",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VentanaEstadisticas({
  paisesMaster,
  estadosMaster,
  numeroMundo,
  numeroEstado,
  numeroPais,
  datosFireBase,
  setPosicion,
  setZoom,
  posicion,
  abrirUbicacionesCercanas,
  setAbrirUbicacionesCercanas,
}) {
  const history = useHistory();

  /**
   * Responsive Design
   */

  const phoneMediaQuery = useMediaQuery("(max-width:480px)");

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [abrirAyuda, setAbrirAyuda] = useState(false);
  const [abrirPreguntas, setAbrirPreguntas] = useState(false);

  const [arrayCalculoUbicaciones, setArrayCalculoUbicaciones] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const abrirPuertas = (validador) => {
    if (validador === "instagram") {
      window.open("https://www.instagram.com/bemydog6/");
    }
    if (validador === "facebook") {
      window.open("https://www.facebook.com/bemydog6");
    }
    if (validador === "salir") {
      Swal.fire({
        title: "¿Estas seguro que quieres cerrar sesion?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cerrar Sesion",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          firebase.auth().signOut();
          history.push("/");
        }
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    const latitude = posicion[0];
    const longitude = posicion[1];

    const getKilometros = (lat1, lon1, lat2, lon2) => {
      let rad = function (x) {
        return (x * Math.PI) / 180;
      };
      let R = 6378.137; //Radio de la tierra en km
      let dLat = rad(lat2 - lat1);
      let dLong = rad(lon2 - lon1);
      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(lat1)) *
          Math.cos(rad(lat2)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let d = R * c;
      return d.toFixed(3); //Retorna tres decimales
    };

    let arrayMaster = [];

    datosFireBase.forEach((elemento) => {
      arrayMaster.push({
        nombre: elemento.raza,
        distancia: parseFloat(
          getKilometros(
            latitude,
            longitude,
            elemento.direccionCoordenadas.latitude,
            elemento.direccionCoordenadas.longitude
          )
        ),
        categoria: "agua",
        description: elemento.descripcion,
        latitude: elemento.direccionCoordenadas.latitude,
        longitude: elemento.direccionCoordenadas.longitude,
        fotos: elemento.fotos,
        direccionCoordenadas: elemento.direccionCoordenadas,
      });
    });

    setArrayCalculoUbicaciones(
      arrayMaster.sort((a, b) => a.distancia - b.distancia)
    );
  }, [datosFireBase]);

  return (
    <Grid
      style={{
        display: "flex",
        alignItems: "flex-end",
      }}
    >
      <div
        style={{
          zIndex: 400,
          display: "flex",
        }}
      >
        {/*Ubicaciones Mas cercanas*/}
        <Grid item>
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<NotListedLocationIcon />}
            onClick={() => setAbrirUbicacionesCercanas(true)}
            style={{ backgroundColor: "#21b547", color: "white" }}
          >
            {/* <Hidden xsDown>Perros Mas Cercanos a mi</Hidden> */}
            Perros Mas Cercanos a mi
          </Button>
        </Grid>

        {/*Preguntas Frecuentes*/}

        <Grid item>
          <Button
            className={classes.button}
            startIcon={<HelpOutlineIcon />}
            onClick={() => setAbrirPreguntas(true)}
            style={{ backgroundColor: "#21aec0", color: "white" }}
          >
            {/* <Hidden xsDown> Preguntas Y Consejos</Hidden> */}
            {phoneMediaQuery ? "" : "Preguntas Y Consejos"}
          </Button>
        </Grid>

        {/*Publicar Ayuda*/}

        {/* <Grid item>
        <Button
          variant="contained"
          className={classes.button}
          startIcon={<SentimentVerySatisfiedIcon />}
          onClick={() => setAbrirAyuda(true)}
          style={{ zIndex: 400, backgroundColor: "#6c29d1", color: "white" }}
        >
          <Hidden xsDown> Publicar ayuda</Hidden>
        </Button>
      </Grid> */}

        {/*Estadisticas*/}

        {/* <Grid item>
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<EqualizerIcon />}
            onClick={handleClickOpen}
            style={{ backgroundColor: "#ff4919", color: "white" }}
          >
            {/* <Hidden xsDown> Estadisticas</Hidden> */}
        {/* Estadisticas
          </Button>
        </Grid> */}

        {/*Instagram*/}

        {phoneMediaQuery && (
          <div
            style={{
              zIndex: 400,
              display: "flex",
              position: "absolute",
              right: 5,
              bottom: 5,
            }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<InstagramIcon />}
                onClick={() => abrirPuertas("instagram")}
              >
                {/* <Hidden xsDown> Instagram</Hidden> */}
                {""}
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<FacebookIcon />}
                onClick={() => abrirPuertas("facebook")}
                style={{ backgroundColor: "#3b5998", color: "white" }}
              >
                {/* <Hidden xsDown> Facebook </Hidden> */}
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                style={{ backgroundColor: "#fff", color: "white" }}
              >
                <img src={iconoLargo} style={{ width: 110 }} />
              </Button>
            </Grid>
          </div>
        )}

        {phoneMediaQuery ? (
          <></>
        ) : (
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<InstagramIcon />}
              onClick={() => abrirPuertas("instagram")}
            >
              {/* <Hidden xsDown> Instagram</Hidden> */}
              Instagram
            </Button>
          </Grid>
        )}

        {/*Facebook*/}

        {phoneMediaQuery ? (
          <></>
        ) : (
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<FacebookIcon />}
              onClick={() => abrirPuertas("facebook")}
              style={{ backgroundColor: "#3b5998", color: "white" }}
            >
              {/* <Hidden xsDown> Facebook </Hidden> */}
              Facebook
            </Button>
          </Grid>
        )}

        {phoneMediaQuery ? (
          <></>
        ) : (
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              style={{ backgroundColor: "#fff", color: "white" }}
            >
              <img src={iconoLargo} style={{ width: 156 }} />
            </Button>
          </Grid>
        )}

        {/*Cerrar Sesion*/}

        {/* <Grid item>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<ExitToAppIcon />}
          onClick={() => abrirPuertas("salir")}
          style={{ zIndex: 400, backgroundColor: "red", color: "white" }}
        >
          <Hidden xsDown> Cerrar Sesion</Hidden>
        </Button>
      </Grid> */}
      </div>

      {/*VENTANA Estadisticas*/}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Estadisticas
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid className={classes.root}>
          <Grid container spacing={3}>
            {/* Dougnut Estadistica */}
            <Grid item lg={4} xs={12} md={4}>
              <Paper elevation={3}>
                <Doughnut
                  paisesMaster={paisesMaster}
                  estadosMaster={estadosMaster}
                  numeroMundo={numeroMundo}
                  numeroEstado={numeroEstado}
                  numeroPais={numeroPais}
                />
                <Typography component="div">
                  <Box
                    textAlign="center"
                    fontSize={30}
                    m={1}
                    style={{ marginBottom: 40 }}
                  >
                    N° de Llamadas de auxilio
                  </Box>
                </Typography>
              </Paper>
            </Grid>

            {/* Polar Estadistica */}
            <Grid item lg={4} xs={12} md={4}>
              <Paper elevation={3}>
                <PolarCopera
                  paisesMaster={paisesMaster}
                  estadosMaster={estadosMaster}
                  numeroMundo={numeroMundo}
                  numeroEstado={numeroEstado}
                  numeroPais={numeroPais}
                />
                <Typography component="div">
                  <Box
                    textAlign="center"
                    fontSize={30}
                    m={1}
                    style={{ marginBottom: 40 }}
                  >
                    N° de Llamadas de auxilio
                  </Box>
                </Typography>
              </Paper>
            </Grid>

            {/* Barras Estadistica */}
            <Grid item lg={4} xs={12} md={4}>
              <Paper elevation={3}>
                <BarCopera
                  paisesMaster={paisesMaster}
                  estadosMaster={estadosMaster}
                  numeroMundo={numeroMundo}
                  numeroEstado={numeroEstado}
                  numeroPais={numeroPais}
                />
                <Typography component="div">
                  <Box
                    textAlign="center"
                    fontSize={30}
                    m={1}
                    style={{ marginBottom: 40 }}
                  >
                    N° de Llamadas de auxilio
                  </Box>
                </Typography>
              </Paper>
            </Grid>

            {/* Grupo de Numeros */}
            <Grid item lg={4} xs={12} md={4}>
              <Paper elevation={3}>
                <CirculoNumero number={numeroPais} />

                <Typography component="div">
                  <Box
                    textAlign="center"
                    fontSize={30}
                    m={1}
                    style={{ marginBottom: 40 }}
                  >
                    {paisesMaster}
                  </Box>
                </Typography>
              </Paper>
            </Grid>

            <Grid item lg={4} xs={12} md={4}>
              <Paper elevation={3}>
                <CirculoNumero number={numeroEstado} />
                <Typography component="div">
                  <Box
                    textAlign="center"
                    fontSize={30}
                    m={1}
                    style={{ marginBottom: 40 }}
                  >
                    {estadosMaster}
                  </Box>
                </Typography>
              </Paper>
            </Grid>

            <Grid item lg={4} md={4} xs={12}>
              <Paper elevation={3}>
                <CirculoNumero number={numeroMundo} />
                <Typography component="div">
                  <Box
                    textAlign="center"
                    fontSize={30}
                    m={1}
                    style={{ marginBottom: 40 }}
                  >
                    Todo el mundo
                  </Box>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      {/* Ventana para Pedir ayuda */}
      <DialogAyuda setAbrirAyuda={setAbrirAyuda} abrirAyuda={abrirAyuda} />

      {/* Ventana de Preguntas Frecuentes   */}
      <DialogPreguntas
        setAbrirPreguntas={setAbrirPreguntas}
        abrirPreguntas={abrirPreguntas}
      />

      {/* Ventana de Ubicaciones mas Cercanas */}

      <Drawer
        width={200}
        anchor="left"
        open={abrirUbicacionesCercanas}
        onClose={() => setAbrirUbicacionesCercanas(false)}
      >
        <div
          className={clsx(classes.list, {
            [classes.fullList]: "left" === "top" || "left" === "bottom",
          })}
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
        >
          {phoneMediaQuery ? (
            <p
              style={{
                margin: "auto",
                padding: 12,
                textAlign: "center",
                fontSize: 10,
              }}
            >
              Esta es una lista de todos los perros en adopción acomodada de la
              más cercana a la más lejana desde donde tú estás actualmente:
            </p>
          ) : (
            <p style={{ margin: "auto", padding: 12, textAlign: "center" }}>
              Esta es una lista de todos los perros en adopción acomodada de la
              más cercana a la más lejana desde donde tú estás actualmente:
            </p>
          )}

          <List>
            <Divider />

            {arrayCalculoUbicaciones.length !== 0 ? (
              arrayCalculoUbicaciones.map((datos, index) => (
                <div key={index}>
                  <ListItem
                    button
                    key={index}
                    style={{ justifyContent: "space-between" }}
                    onClick={() => {
                      setPosicion([
                        datos.direccionCoordenadas.latitude,
                        datos.direccionCoordenadas.longitude,
                      ]);
                      setZoom(20);
                    }}
                  >
                    {!phoneMediaQuery && (
                      <div>
                        <p style={{ fontWeight: 600, marginBottom: 0 }}>
                          {datos.nombre}
                        </p>
                        <p style={{ marginTop: 0, fontSize: 14 }}>
                          {datos.description.substr(0, 80)}...
                        </p>
                        <p>Presiona para mas informacion</p>
                      </div>
                    )}

                    <div style={{ marginLeft: 10 }}>
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={datos.fotos[0]}
                          style={{
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                          width="100"
                          height="100"
                          onLoad={() => console.log("listoo")}
                        />

                        {/* <LazyLoadImage
            alt="product"
            src={datos.fotos[0]}
            // src={ process.env.NEXT_PUBLIC_ASSET_URI + product.pictures[ 0 ].url }
            threshold={500}
            effect="opacity"
            width="100"
            height="100"
            style={{
              borderRadius: "50%",
            }}
          /> */}
                      </div>
                      {phoneMediaQuery && (
                        <p
                          style={{
                            fontWeight: 600,
                            marginBottom: 8,
                            marginTop: 8,
                            textAlign: "center",
                          }}
                        >
                          {datos.nombre}
                        </p>
                      )}

                      <div style={{ textAlign: "center" }}>
                        <Chip label={datos.distancia + " Km "} />
                      </div>
                    </div>
                  </ListItem>
                  <Divider />
                </div>
              ))
            ) : phoneMediaQuery ? (
              <p
                style={{
                  margin: "auto",
                  padding: 12,
                  textAlign: "center",
                  fontSize: 10,
                }}
              >
                Actualmente no hay ningún perro en el país y estado que elegiste
              </p>
            ) : (
              <p style={{ margin: "auto", padding: 12, textAlign: "center" }}>
                Actualmente no hay ningún perro en el país y estado que elegiste
              </p>
            )}
          </List>
        </div>
      </Drawer>
    </Grid>
  );
}
