import React from 'react';
import {Bar} from 'react-chartjs-2';



const BarCopera = (
    {
        paisesMaster,
        estadosMaster,
        numeroMundo,
        numeroEstado,
        numeroPais}
) => {



    const data = {
        labels: [
            estadosMaster,
            paisesMaster,
            'Todo el mundo'
        ],
        datasets: [
          {
            label: 'Llamadas de auxilio',
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [numeroEstado, numeroPais, numeroMundo]
          }
        ]
      };


    return (
        
        <Bar
          data={data}
          width={20}
          height={150}
          options={{
            maintainAspectRatio: false
          }}
        />
        
      
      );
}
 
export default BarCopera;

