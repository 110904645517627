import React,{useEffect,useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Container from '@material-ui/core/Container';
import firebase from 'firebase/app';
import 'firebase/auth';






const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

export default function Contraseña(props) {

  const [datosUsuario, setDatosUsuario] = useState({
      email: '',
  });

  const [correoCiudadano, setCorreoCiudadano] = useState(false);

  const [numero, setNumero] = useState(null);
  const [comprobadorAlerta, setComprobadorAlerta] = useState('');
  const [logueado, setLogueado] = useState(false);

  const classes = useStyles();

   const validateEmail = (email)=> {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  


  const nuevoUsuario= async (e)=>{
      e.preventDefault();
      setLogueado(true);

      if(validateEmail(datosUsuario.email) === false){
        setComprobadorAlerta('El email ingresado no es valido, verifica si lo escribiste bien');
        setLogueado(false);
        return;
      }


      if(datosUsuario.password === datosUsuario.repeatPassword  && validateEmail(datosUsuario.email) === true){

        try{
          await firebase.auth().sendPasswordResetEmail(datosUsuario.email).then(()=> 
          setComprobadorAlerta('El correo se ah enviado correctamente'));
   
         }
         catch(e){
          
            
           
              setComprobadorAlerta(true);
              setComprobadorAlerta('Hubo un error');
              
            
         }


      }
      setLogueado(false);
     
      
  }

  const onChange = (e)=>{
        setDatosUsuario({
          ...datosUsuario,
          [e.target.name]:e.target.value

        })

  }

  useEffect(()=>{
    
    firebase.auth().onAuthStateChanged(user=>{

      if(user){
        
          props.history.push('/mapa-copera');
          
          
      }else{
          
      }
        
    })



},[])



  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <img src={require('./imagenes/logo-copera.png')} style={{width:240, height:80}}></img>
        <Typography component="h1" variant="h5" style={{paddingBottom:10 }}>
          ¿Olvidaste tu contraseña?
        </Typography>
  {comprobadorAlerta !== '' && <Alert severity={comprobadorAlerta === 'El correo se ah enviado correctamente' ? 'success':"error"}>{comprobadorAlerta}</Alert>}
  <Grid style={{marginTop:10}}>
          <Typography>
            Por favor ingresa tu direccion de correo electronico y te enviaremos la informacion para cambiar tu contraseña.
          </Typography>
        </Grid>
        <form className={classes.form}  onSubmit={nuevoUsuario} >
          <Grid container spacing={2}>
            
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={false}
                
                fullWidth
                id="email"
                label="Correo Electronico"
                name="email"
                autoComplete="email"
                onChange={onChange}
              />
            </Grid>
        
           
          
           
           
        
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Resetear Contraseña
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/" variant="body2">
                Ir a la pagina de inicio
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Backdrop className={classes.backdrop} open={logueado}>
          <CircularProgress color="inherit"/>
        </Backdrop >
      
    </Container>

  );
}