const popupContent = {
  height: "auto",
  marginTop: "30px",
  zIndex: 6000,
};
const popupHead = {
  fontWeight: "bold",
  fontSize: "15px",
  textAlign: "center",
  paddingBottom: "10px",
  marginTop: "10px",
};

const popupText = {
  fontSize: "12px",
  marginBottom: "20px",
  textAlign: "justify",
};

const okText = {
  fontSize: "15px",
};

export { popupContent, popupHead, popupText, okText };
