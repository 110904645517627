import React, { useEffect, useState } from "react";
import paises from "./Paises/countries";
import estados from "./Paises/states";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import Container from "@material-ui/core/Container";
import firebase from "firebase/app";
import "firebase/auth";
import { firebaseApp } from "./components/FireBase";
import "firebase/firestore";
const db = firebaseApp.firestore(firebaseApp);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function InicioCuenta(props) {
  const [datosUsuario, setDatosUsuario] = useState({
    email: "",
    password: "",
    repeatPassword: "",
    pais: "",
    estado: "",
  });

  const [correoCiudadano, setCorreoCiudadano] = useState(false);

  const [numero, setNumero] = useState(null);
  const [comprobadorAlerta, setComprobadorAlerta] = useState("");
  const [logueado, setLogueado] = useState(false);

  const estadosLista = estados.states;
  const paisesLista = paises.countries;

  const classes = useStyles();

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const nuevoUsuario = async (e) => {
    e.preventDefault();
    setLogueado(true);

    if (validateEmail(datosUsuario.email) === false) {
      setComprobadorAlerta(
        "El email ingresado no es valido, verifica si lo escribiste bien"
      );
      setLogueado(false);
      return;
    }

    if (datosUsuario.password !== datosUsuario.repeatPassword) {
      setComprobadorAlerta("Las contraseñas no son iguales");
      setLogueado(false);
      return;
    }

    if (
      datosUsuario.password === datosUsuario.repeatPassword &&
      validateEmail(datosUsuario.email) === true
    ) {
      try {
        await firebase
          .auth()
          .createUserWithEmailAndPassword(
            datosUsuario.email,
            datosUsuario.password
          );
        const resultado = await db
          .collection("datosUsuarios")
          .doc("3wGMu28L7jtf2KopNJQn");
        resultado
          .update({
            datos: firebase.firestore.FieldValue.arrayUnion({
              correo: datosUsuario.email,
              pais: datosUsuario.pais,
              estado: datosUsuario.estado,
              correos: correoCiudadano,
            }),
          })
          .then(() => props.history.push("mapa-copera"));
      } catch (e) {
        if (
          e.message ===
          "The email address is already in use by another account."
        ) {
          setComprobadorAlerta(true);
          setComprobadorAlerta("Ya existe una cuenta con este correo");
        }
      }
    }
    setLogueado(false);
  };

  const onChange = (e) => {
    setDatosUsuario({
      ...datosUsuario,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (props.validadorMapa === false) {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          props.history.push("/mapa-copera");
        } else {
        }
      });
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img
          src={require("./imagenes/logo-copera.png")}
          style={{ width: 240, height: 80 }}
        ></img>
        <Typography component="h1" variant="h5" style={{ paddingBottom: 10 }}>
          Crear una cuenta
        </Typography>
        {comprobadorAlerta !== "" && (
          <Alert severity="error">{comprobadorAlerta}</Alert>
        )}

        <form className={classes.form} onSubmit={nuevoUsuario}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={false}
                fullWidth
                id="email"
                label="Correo Electronico"
                name="email"
                autoComplete="email"
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="repeatPassword"
                label="Repetir Contraseña"
                type="password"
                id="repeatPassword"
                autoComplete="current-password"
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Pais
                </InputLabel>
                <Select
                  native
                  onChange={(e) => {
                    setDatosUsuario({
                      ...datosUsuario,
                      [e.target.name]: e.target.value,
                      estado: "",
                    });

                    paisesLista.forEach((element) => {
                      if (e.target.value === element.name) {
                        setNumero(parseInt(element.id));
                      }
                    });
                  }}
                  label="Pais"
                  name="pais"
                >
                  <option aria-label="None" value="" />
                  {paisesLista.map((pais, index) => {
                    return (
                      <option value={pais.name} key={index}>
                        {pais.name}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
                required
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Estado
                </InputLabel>
                <Select native onChange={onChange} label="Estado" name="estado">
                  <option aria-label="None" value="" />
                  {numero &&
                    estadosLista
                      .filter((sigma) => numero === sigma.id_country)
                      .map((au, index) => (
                        <option key={index} value={au.name}>
                          {au.name}
                        </option>
                      ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox value="allowExtraEmails" color="primary" required />
                }
                label="Acepto"
                style={{ marginRight: 5 }}
              />
              <a href="/terminos-y-condiciones.pdf" target="_blank">
                Terminos y condiciones
              </a>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                onClick={() => {
                  setCorreoCiudadano(!correoCiudadano);
                }}
                label={
                  "Quiero recibir un correo cada vez que se apruebe una nueva ayuda en mi estado" +
                  "\n" +
                  "(Ej. si en un estado hay un millon de usuarios de Co-Pera19 y se aprueba una llamada de auxilio en ese estado, a ese millon de personas les llega un correo con los datos de ayuda, imagina las vidas que se podrian salvar solo por aceptar esta casilla, es totalmente GRATUITO)"
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Crear Cuenta
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/" variant="body2">
                ¿Ya tienes una cuenta? Inicia Sesion
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Backdrop className={classes.backdrop} open={logueado}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
