import React,{useEffect,useState} from 'react';
import paises from './Paises/countries';
import estados from './Paises/states';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import firebase from 'firebase/app';
import 'firebase/auth';
import {firebaseApp} from './components/FireBase';
import 'firebase/firestore';
const db = firebase.firestore(firebaseApp);


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

export default function CrearAyuda(props) {

    const currencies = [
        { label: 'Higiene', value: 'higiene' },
        { label: 'Alimentos', value: 'alimentos' },
        { label: 'Agua', value: 'agua' },
        { label: 'Insumos medicos', value: 'insumos medicos' },
        { label: 'Otros', value: 'otros' },
        { label: 'Ropa', value: 'ropa' },
        { label: 'Punto publico de ayuda', value: 'punto publico de ayuda' },
        { label: 'Hospitales', value: 'hospitales' }
      ];

  const [datosUsuario, setDatosUsuario] = useState({
      email: '',
      pais: '',
      estado: '',
      titulo: '',
      descripcion: '',
      telefono: '',
      direccion: '',
      categoria: '',
      geolocalizacion: ''



  });

  const [correoCiudadano, setCorreoCiudadano] = useState(false);

  const [numero, setNumero] = useState(null);
  const [comprobadorAlerta, setComprobadorAlerta] = useState('');
  const [logueado, setLogueado] = useState(false);
  const [comprobadorEnvio, setComprobadorEnvio] = useState(false);

  
  const estadosLista = estados.states;
  const paisesLista = paises.countries;

  const classes = useStyles();

   const validateEmail = (email)=> {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  


  const nuevoUsuario= async (e)=>{
      e.preventDefault();
      setLogueado(true);

      let empezar = false;
let latitude = '';
let longitude = '';
let direccion = datosUsuario.geolocalizacion;
let sumador = 0;
for(let i = 0; i <= direccion.length-1 ; i++){
    
    
     if(sumador === 1  && direccion[i] !== ',' ){
         latitude+=direccion[i]
         
    }
    
    if(sumador === 2 && direccion[i] !== ',' ){
         longitude+=direccion[i]
         
    }
    
      if(direccion[i] === ',' && sumador ===2){
        
        sumador++;
     
    }

      if(direccion[i] === ',' && empezar === true){
        
        sumador++;
        
    }

     if(direccion[i] === '@'){
        empezar = true
        sumador++;
       
    
    }
}



      db.collection('restaurants').add({

        name: datosUsuario.titulo,
        addresss: datosUsuario.direccion,
        description: datosUsuario.descripcion,
        location: {
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude)
         },
        estado: datosUsuario.estado,
        filtroRevision: false,
        telefono: datosUsuario.telefono, 
        correo: datosUsuario.email,
        categoria:  datosUsuario.categoria,
        createAt: new Date(),
        createBy: firebaseApp.auth().currentUser.uid,
        pais: datosUsuario.pais
      }).then(()=>{
        setComprobadorAlerta('Ayuda enviada con exito, tus datos estan siendo verificados por el equipo de Co-Pera19, una vez validados se te notificara mediante un correo si fue aprobada o no. (Este protocolo es por motivos de seguridad)');  
        setComprobadorEnvio(true);
        setLogueado(false);
    }).catch(()=>{
        setComprobadorAlerta('Hubo un error');
        setLogueado(false);
        
    })

     
      
     
      
  }

  const onChange = (e)=>{
        setDatosUsuario({
          ...datosUsuario,
          [e.target.name]:e.target.value

        })

  }

  useEffect(()=>{
    
  


},[])



  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <img src={require('./imagenes/logo-copera.png')} style={{width:240, height:80}}></img>
        <Typography component="h1" variant="h5" style={{paddingBottom:10 }}>
          Llamada de Auxilio
        </Typography>
        <Alert severity="info">Por seguridad, a menos que seas un hospital.. Co-Pera19 te recomienda poner tu ubicacion a unos metros de tu ubicacion exacta</Alert>
        
        
        <form className={classes.form}  onSubmit={nuevoUsuario} >
          <Grid container spacing={2}>

          <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={false}
                required
                fullWidth
                id="titulo"
                label="Titulo ej: Necesitamos agua, alimento...."
                name="titulo"
                autoComplete="titulo"
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={false}
                multiline
                fullWidth
                id="descripcion"
                label="Descripcion de la ayuda..."
                name="descripcion"
                autoComplete="descripcion"
                onChange={onChange}
                required
              />
            </Grid>


            
            
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={false}
                required
                fullWidth
                id="email"
                label="Correo de contacto"
                name="email"
                autoComplete="email"
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={false}
                required
                fullWidth
                id="telefono"
                label="Telefono de contacto"
                name="telefono"
                autoComplete="telefono"
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={false}
                required
                fullWidth
                id="direccion"
                label="Direccion"
                name="direccion"
                autoComplete="direccion"
                onChange={onChange}
              />
            </Grid>
          
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                error={false}
                required
                fullWidth
                helperText={"Para aparecer en el mapa de Co-Pera19 es necesario que busques tu direccion o el lugar donde es la ayuda en Google maps y posteriormente pegues el link en este campo"}
                id="geolocalizacion"
                label="Geolocalizacion"
                name="geolocalizacion"
                autoComplete="geolocalizacion"
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
            <TextField
          id="outlined-select-currency"
          required
          select
          label="Categoria"
          value={datosUsuario.categoria}
          onChange={onChange}
          name='categoria'
          helperText="Selecciona una categoria"
          variant="outlined"
          
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        </Grid>
         
            
            <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" className={classes.formControl} required>
        <InputLabel htmlFor="outlined-age-native-simple">Pais</InputLabel>
        <Select
          native
          
          onChange={(e)=>{

            setDatosUsuario({
              ...datosUsuario,
              [e.target.name]:e.target.value,
              estado:''
    
            })

            paisesLista.forEach(element =>{
              if(e.target.value === element.name){
                setNumero(parseInt(element.id));
                
              }
            })
          
          }}
          label="Pais"
         name='pais'
        >
          <option aria-label="None" value="" />
          {paisesLista.map((pais,index)=>{
           return(
    
          <option value={ pais.name} key={index}  >{pais.name}</option>
          );
          })}
          
        </Select>
      </FormControl>
            </Grid> 
            <Grid item xs={12} sm={6} >
            <FormControl variant="outlined" className={classes.formControl} fullWidth required>
        <InputLabel htmlFor="outlined-age-native-simple" >Estado</InputLabel>
        <Select
          native
          
          onChange={onChange}
          label="Estado"
         name='estado'
         
        >
          <option aria-label="None" value="" />
           {numero &&
             
            estadosLista.filter((sigma)=>(

               numero === sigma.id_country
            )).map((au,index)=>(
             
            <option key={index} value={au.name}>{au.name}</option>
            ))
            
              
           
               }
          
          
          
        </Select>
      </FormControl>
            </Grid>
           
          
          </Grid>
          {comprobadorAlerta !== '' && <Alert severity="success" style={{marginTop:20}}>{comprobadorAlerta}</Alert>}
          {comprobadorEnvio ===true ?<Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled
            
          >
            Enviar Ayuda
          </Button>:
          <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          
        >
          Enviar Ayuda
        </Button>

}
         
        
        </form>
      </div>
      <Backdrop className={classes.backdrop} open={logueado}>
          <CircularProgress color="inherit"/>
        </Backdrop >
      
    </Container>

  );
}