import L from 'leaflet';


const blueIcon = new L.Icon({
	iconUrl: require('./img/marker-icon-2x-blue.png'),
	shadowUrl:  require('./img/marker-shadow.png'),
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

const goldIcon = new L.Icon({
	iconUrl: require('./img/marker-icon-2x-gold.png'),
	shadowUrl:  require('./img/marker-shadow.png'),
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

const redIcon = new L.Icon({
	iconUrl: require('./img/marker-icon-2x-red.png'),
	shadowUrl:  require('./img/marker-shadow.png'),
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

const greenIcon = new L.Icon({
	iconUrl: require('./img/marker-icon-2x-green.png'),
	shadowUrl: require('./img/marker-shadow.png'),
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

const orangeIcon = new L.Icon({
	iconUrl: require('./img/marker-icon-2x-orange.png'),
	shadowUrl:  require('./img/marker-shadow.png'),
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

const yellowIcon = new L.Icon({
	iconUrl: require('./img/marker-icon-2x-yellow.png'),
	shadowUrl:  require('./img/marker-shadow.png'),
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

const violetIcon = new L.Icon({
	iconUrl: require('./img/marker-icon-2x-violet.png'),
	shadowUrl:  require('./img/marker-shadow.png'),
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

const greyIcon = new L.Icon({
	iconUrl: require('./img/marker-icon-2x-grey.png'),
	shadowUrl:  require('./img/marker-shadow.png'),
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

const blackIcon = new L.Icon({
	iconUrl: require('./img/marker-icon-2x-black.png'),
	shadowUrl:  require('./img/marker-shadow.png'),
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});


  export {greenIcon, blueIcon, redIcon, blackIcon, greyIcon,violetIcon, yellowIcon, orangeIcon, goldIcon};