import React from 'react';
import {Polar} from 'react-chartjs-2';



const PolarCopera = ({ paisesMaster,
    estadosMaster,
    numeroMundo,
    numeroEstado,
    numeroPais}) => {

        const data = {
            datasets: [{
                data: [numeroEstado, numeroPais, numeroMundo],
              backgroundColor: [
                '#FF6384',
                '#4BC0C0',
                '#FFCE56'

              ],
              label: 'My dataset' // for legend
            }],
            labels: [
                estadosMaster,
                paisesMaster,
                'Todo el mundo'
            ]
          };

    return ( 
      <>
        
        <Polar data={data}
         height={150} 
         options={{ maintainAspectRatio: false }}
        />
      </>
     );
}
 
export default PolarCopera;

