import React from 'react';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CrearAyuda from './CrearAyuda';

const DialogAyuda = ({abrirAyuda, setAbrirAyuda}) => {

     


    return ( 
        <Dialog
        open={abrirAyuda}
        onClose={()=> setAbrirAyuda(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Llena el siguiente formulario para enviar los datos de ayuda</DialogTitle>
        <DialogContent>
    
          
          <CrearAyuda />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=> setAbrirAyuda(false)} color="primary">
            Cancelar
          </Button>
          
        </DialogActions>
      </Dialog>
     );
}
 
export default DialogAyuda;